import { HandleActionPayload, PageId } from '../../constants';
import { ComponentStructure } from '../../migration/domain';
import { getNewCalendarPageData } from '../calendar-page-migration/settings-data-transpiler';
import { getNewCalendarPageStyles } from '../calendar-page-migration/styles-transpiler';
import { BasePageMigrator } from './base-page-migrator';
import { isBookingCalendarInstalled } from '../editor-sdk-actions';
import { addBookingCheckoutPage } from '../pages-actions';

export class CalendarPageMigrator extends BasePageMigrator {
  constructor(protected editorSDK, protected appToken, protected instance, protected handleActionPayload: HandleActionPayload) {
    super(editorSDK, appToken, instance, handleActionPayload);
  }

  public async shouldMigrate(): Promise<boolean> {
    const bookingCalendarInstalled = await isBookingCalendarInstalled(this.editorSDK);

    return !bookingCalendarInstalled;
  }

  public async execute() {
    await this.addBookingsPage(PageId.BOOKINGS_CALENDAR_PAGE);

    let siteStructure = await this.getSiteStructure();
    let checkoutStructure: ComponentStructure = this.findWidgetStructure(
      siteStructure,
      this.bookingCheckoutWidgetId,
      PageId.BOOKINGS_CHECKOUT
    );

    const calendarStructure: ComponentStructure = this.findWidgetStructure(
      siteStructure,
      this.widgetId,
      PageId.BOOKINGS_CALENDAR_PAGE
    );

    if (!checkoutStructure) {
      await addBookingCheckoutPage(this.editorSDK, this.appToken);

      siteStructure = await this.getSiteStructure();
      checkoutStructure = this.findWidgetStructure(
        siteStructure,
        this.bookingCheckoutWidgetId,
        PageId.BOOKINGS_CHECKOUT
      );

      if (!checkoutStructure) {
        throw new Error('Bookings Checkout Page not exist twice - errorCode: ff0ff21b-1373-4bdc-8ee5-8fb1d02e46a2');
      }
    }

    if (checkoutStructure.style?.style?.properties) {
      console.log('Old Calendar Page Properties:');
      console.log(checkoutStructure);
      console.table(checkoutStructure.style.style.properties);
      console.log('New Calendar Page structure: (Before Change)');
      console.log(calendarStructure);
      console.table(calendarStructure.style?.style?.properties);
      const newCalendarPageData = getNewCalendarPageData(
        checkoutStructure.style.style.properties,
      );
      console.log('Layouts to write:');
      console.table(newCalendarPageData);
      if (newCalendarPageData) {
        await this.updateComponentSettings(
          calendarStructure.id,
          newCalendarPageData,
        );
      }
      const newCalendarPageStyles = getNewCalendarPageStyles(
        checkoutStructure.style.style.properties,
      );
      console.log('Styles to write:');
      console.table(newCalendarPageStyles);

      if (newCalendarPageStyles) {
        await this.updateComponentStyles(
          calendarStructure.id,
          newCalendarPageStyles,
        );
      }
    } else {
      console.log('Old calendar page is plain, no styles to migrate.');
    }

    await this.createPageState(
      PageId.BOOKINGS_CALENDAR_PAGE,
      'bookingCalendar',
    );
  }

  protected get widgetId() {
    return '54d912c5-52cb-4657-b8fa-e1a4cda8ed01';
  }

  public get widgetName() {
    return 'CalendarPageMigrator';
  }
}
